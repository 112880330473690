
import { Component, Provide, Prop, Watch, Vue } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import scroll from 'scroll';
import { EventHandler } from 'fourwaves-shared';
import { DynamicElement } from 'fourwaves-shared/components';
import { IScrollContext, ScrollActionOptions, ScrollDirections } from '~/types/scroll-context';
import { v4 as uuid } from 'uuid';

enum ClassNames {
  SnapBottom = 'scroll-context-snap-bottom',
}

@Component({
  components: {
    DynamicElement,
  },
})
export default class ScrollContext extends Vue {
  @Prop({ type: String, default: null }) readonly name!: string | null;
  @Prop({ type: String, default: null }) readonly contentClass!: string | null;
  @Prop({ type: String, default: 'div' }) readonly contentTag!: string;
  @Prop(Boolean) readonly snapToBottom!: boolean;
  @Prop(Boolean) readonly infiniteWrapper!: boolean;

  $refs!: {
    simplebarElement?: HTMLDivElement;
  };

  instance: SimpleBar | null = null;
  removeEventHandlers: [string, EventHandler][] = [];
  position = 0;
  direction: ScrollDirections | null = null;
  mute = true;
  timeout: NodeJS.Timeout | null = null;
  contextKey = uuid();
  elements: Record<'scroll' | 'content', HTMLElement | null> = {
    scroll: null,
    content: null,
  };

  get isScrolled() {
    return !this.snapToBottom ? this.position >= 5 : !this.isAtBottom;
  }

  get isAtBottom() {
    if (!this.elements.scroll?.scrollHeight || !this.elements.scroll.clientHeight) return true;
    return Math.abs(this.elements.scroll.scrollHeight - this.position - this.elements.scroll.clientHeight) <= 5;
  }

  get className() {
    return { '-scrolled': this.isScrolled };
  }

  @Provide() scrollContext: IScrollContext = {
    name: this.name,
    goTo: this.goTo,
    goToTop: this.goToTop,
    goToBottom: this.goToBottom,
    elements: this.elements,
    canScroll: this.canScroll,
    addListener: (handler: EventHandler<number>, immediate?: boolean) => {
      if (immediate) handler(this.position);
      this.$on('scroll', handler);
      return () => this.$off('scroll', handler);
    },
    removeListener: (handler: EventHandler<number>) => {
      this.$off('scroll', handler);
    },
  };

  @Watch('direction')
  onDirectionChange(direction: ScrollDirections | null) {
    if (!direction) return;
    this.$emit('scroll-direction-changed', direction);
  }

  @Watch('isScrolled')
  onIsScrolledChange(isScrolled: boolean) {
    this.$emit('scrolled', isScrolled);
    this.direction = isScrolled ? ScrollDirections.Down : ScrollDirections.Up;
  }

  @Watch('isAtBottom')
  onIsAtBottomChange(isAtBottom: boolean, wasAtBottom: boolean) {
    if (!this.snapToBottom || !this.elements.scroll || isAtBottom === wasAtBottom || !this.canScroll()) return;
    this.elements.scroll.classList.toggle(ClassNames.SnapBottom);
  }

  async mounted() {
    await this.$nextTick();
    if (!this.$refs.simplebarElement) return;
    this.instance = new SimpleBar(this.$refs.simplebarElement);
    this.elements.scroll = this.instance.getScrollElement();
    this.elements.content = this.instance.getContentElement();
    this.initScrollEventHandler();
    this.initScrollPositionWatcher();
    if (this.infiniteWrapper) this.elements.scroll?.setAttribute('infinite-wrapper', 'true');
    if (this.snapToBottom) this.elements.scroll?.classList.add(ClassNames.SnapBottom);
    if (this.name) this._scrollContextInternal.register(this.name, this.scrollContext, this.contextKey);
    this.canScroll();
  }

  beforeDestroy() {
    if (this.name) this._scrollContextInternal.remove(this.name, this.contextKey);
    if (this.timeout) clearTimeout(this.timeout);

    this.removeEventHandlers.forEach(([eventName, handler]) => {
      this.elements.scroll?.removeEventListener(eventName, handler);
    });
  }

  public initScrollEventHandler() {
    let isQueued = false;

    const updateScrollPosition = () => {
      if (this.elements.scroll) this.position = this.elements.scroll.scrollTop;
      isQueued = false;
    };

    const throttledHandler = () => {
      if (isQueued) return;
      isQueued = true;
      requestAnimationFrame(updateScrollPosition);
    };

    this.elements.scroll?.addEventListener('scroll', throttledHandler);
    this.removeEventHandlers.push(['scroll', throttledHandler]);
    updateScrollPosition();
    this.mute = false;
  }

  public initScrollPositionWatcher() {
    let previousBottomPosition: number | null = null;

    this.$watch('position', (position: number) => {
      if (!this.$refs.simplebarElement || !this.elements.scroll || !this.elements.content) return;
      const bottomPosition = this.elements.content.getBoundingClientRect().bottom;
      if (previousBottomPosition === null) previousBottomPosition = bottomPosition;
      const offsetScrollHeight = this.elements.scroll.scrollHeight - this.$refs.simplebarElement.clientHeight;
      const diff = bottomPosition - previousBottomPosition;

      if (Math.abs(diff) > 5 && position > 0 && position < offsetScrollHeight) {
        previousBottomPosition = bottomPosition;
        this.direction = diff > 0 ? ScrollDirections.Up : ScrollDirections.Down;
      }

      if (!this.mute) this.triggerScrollEvent();
    });
  }

  public triggerScrollEvent() {
    this.$emit('scroll', this.position, this.direction);
  }

  public async goTo(target: number | Element = 0, options: ScrollActionOptions = {}): Promise<void> {
    const { duration = 0, offset = 0 } = options;

    return await new Promise(resolve => {
      if (!this.elements.scroll) return resolve();
      const targetPosition = typeof target !== 'number' ? target.getBoundingClientRect().top : target;
      const offsetPosition = targetPosition - offset;

      if (duration) {
        scroll.top(this.elements.scroll, offsetPosition, { duration });
      } else {
        this.elements.scroll.scrollTop = offsetPosition;
        this.position = targetPosition;
        this.triggerScrollEvent();
      }

      setTimeout(resolve, duration);
    });
  }

  public async goToTop(options: ScrollActionOptions = {}) {
    return await this.goTo(0, options);
  }

  public async goToBottom(options: ScrollActionOptions = {}) {
    const bottom = this.elements.scroll?.scrollHeight || 0;
    return await this.goTo(bottom, options);
  }

  public canScroll() {
    if (!this.elements.scroll || !this.$refs.simplebarElement) return false;
    return this.elements.scroll.scrollHeight - this.$refs.simplebarElement.clientHeight > 0;
  }
}
