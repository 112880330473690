
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { PresentationElement, PresentationPresenters } from 'fourwaves-shared/components';
import UserProfilePicture from 'fourwaves-shared/src/components/users/UserProfilePicture.vue';
import PosterCardViewer from './PosterCardViewer.vue';
import { getPresenterUserIds } from 'fourwaves-shared';

@Component({
  components: {
    UserProfilePicture,
    PosterCardViewer,
    PresentationPresenters,
  },
})
export default class PosterCard extends Vue {
  @Prop({ required: true }) readonly presentation!: PresentationElement;
  @Prop(Boolean) readonly isListDisplay!: boolean;
  @Prop(Boolean) readonly withViewers!: boolean;

  get presentationLink() {
    return this.presentation.thumbnail
      ? this.localePath({ name: 'slug-presentations-id-poster', params: { id: this.presentation.id } })
      : this.localePath({ name: 'slug-presentations-id', params: { id: this.presentation.id } });
  }

  get presenterIds(): string[] {
    const presenterUserIds = getPresenterUserIds(this.presentation);
    return presenterUserIds.length ? presenterUserIds : [this.presentation.userId];
  }
}
