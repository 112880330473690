
import { Component, Vue, namespace, Watch } from 'nuxt-property-decorator';
import type { FormDto, PageDto, FormEntryDto, PaginatedList, ScheduleDto, SubmissionScope } from 'fourwaves-shared';
import { getFirstName, getLastName, getSubmissionTitle, PageType, FormFieldType } from 'fourwaves-shared';
import { PresentationElement } from 'fourwaves-shared/components';
import PosterCarousel from '~/components/poster/PosterCarousel.vue';
import LocaleLink from '~/components/common/LocaleLink.vue';
import { StatusFilter } from 'fourwaves-shared/src/services';

const EventsModule = namespace('events');

@Component({ components: { LocaleLink, PosterCarousel } })
export default class PosterHallModule extends Vue {
  @EventsModule.Getter liveSessions!: ScheduleDto[];
  @EventsModule.Getter submissionForm!: FormDto;
  @EventsModule.State pages!: PageDto[];

  virtualPostersData: PaginatedList<FormEntryDto> | null = null;
  liveVirtualPostersData: PaginatedList<FormEntryDto> | null = null;
  page: PageDto | null = null;
  submissionScope: SubmissionScope | null = null;

  get posterCount(): number {
    return this.virtualPostersData ? this.virtualPostersData.totalCount : 0;
  }

  get livePosterCount(): number {
    return this.liveVirtualPostersData ? this.liveVirtualPostersData.totalCount : 0;
  }

  get featuredPosters(): any[] {
    let data: FormEntryDto[] = [];

    if (this.virtualPostersData?.totalCount) {
      data = this.virtualPostersData.items.map(this.buildPresentationElement);
    }

    if (this.liveVirtualPostersData?.totalCount) {
      data = this.liveVirtualPostersData.items.map(this.buildPresentationElement);
    }

    return data;
  }

  async created() {
    this.page = this.pages.find(({ type }) => type === PageType.Abstracts) || null;
    this.submissionScope = this.page?.submissionScope || null;
    this.virtualPostersData = await this.fetchPosters();
    this.liveVirtualPostersData = await this.fetchPosters(true);
  }

  @Watch('liveSessions')
  async onLiveSessionsChanged() {
    this.liveVirtualPostersData = await this.fetchPosters(true);
  }

  public async fetchPosters(isPresentingNow: boolean | undefined = undefined) {
    return await this.$api.getFormEntries({
      formId: this.submissionForm.id,
      isVirtualPoster: true,
      submissionScope: this.submissionScope,
      isPresentingNow,
      pageSize: 4,
      statusFilters: [StatusFilter.Scheduled, StatusFilter.Submitted],
    });
  }

  public buildPresentationElement(presentation: FormEntryDto): PresentationElement {
    const submitterFirstName = getFirstName(presentation, this.submissionForm!);
    const submitterLastName = getLastName(presentation, this.submissionForm!);

    return {
      ...presentation,
      submitterFirstName,
      submitterLastName,
      title: getSubmissionTitle(presentation, this.submissionForm!),
      connectionCount: presentation.videoParticipantCount,
      thumbnail: presentation.thumbnail ? `${presentation.thumbnail}?fit=max&w=800` : undefined,
      hasVideo: presentation.formEntryValues.some(({ fieldType }) => fieldType === FormFieldType.Video),
    };
  }
}
