
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import UserProfilePicture from 'fourwaves-shared/src/components/users/UserProfilePicture.vue';
import { Collapsible } from 'fourwaves-shared/src/components/layout';
import { PosterPresenceChannel, PosterPresenceDetails } from '~/channels';

@Component({
  components: {
    Collapsible,
    UserProfilePicture,
  },
})
export default class PosterCardViewer extends Vue {
  @Prop({ required: true }) readonly presentationId!: string;
  @Prop({ required: true }) readonly presenterIds!: string[];

  viewerData: PosterPresenceDetails | null = null;
  presenceChannel: PosterPresenceChannel | null = null;

  get hasViewers() {
    return !!(this.viewerData && this.viewerData.viewerCount);
  }

  get reversedViewersList() {
    // need to reverse() because of row-reverse positionning
    if (!this.viewerData) return [];
    return Array.from(this.viewerData.users).reverse();
  }

  created() {
    this.presenceChannel = new PosterPresenceChannel({
      connection: this.$realtime,
      presentationId: this.presentationId,
      presenterIds: this.presenterIds,
      onSyncCallback: viewerData => (this.viewerData = viewerData),
    });
  }

  beforeDestroy() {
    this.presenceChannel?.unsubscribe();
  }

  public onElementObserved() {
    this.presenceChannel?.subscribe();
  }

  public onElementUnobserved() {
    this.presenceChannel?.unsubscribe();
  }
}
