/* eslint-disable @typescript-eslint/no-require-imports */
import { addSharedLocales } from 'fourwaves-shared';
import presentations from './presentations';
import participants from './participants';
import schedule from './schedule';
import errors from './errors';
import dashboard from './dashboard';
import activityFeed from './activity-feed';
import globalChat from './global-chat';
import validation from './validation';
import global from './global';
import conferencing from './conferencing';
import directMessaging from './direct-messaging';
import posters from './posters';

export default addSharedLocales('en', {
  countries: require('fourwaves-shared/src/core/countries.en.json'),
  provinces: require('fourwaves-shared/src/core/provinces.en.json'),
  currencies: require('fourwaves-shared/src/core/currencies.en.json'),
  fourwaves_badge: {
    title: 'Powered by',
    link: 'https://fourwaves.com/',
  },
  presentations,
  participants,
  schedule,
  errors,
  activityFeed,
  global,
  globalChat,
  validation,
  dashboard,
  conferencing,
  posters,
  directMessaging,
});
