import { BaseChannel, BaseChannelParams } from 'fourwaves-shared';
import { PresenceDetails, UserPresence, UserPresenceChannel } from './UserPresenceChannel';

export interface PosterPresenceDetails {
  videoParticipantsCount: number;
  viewerCount: number;
  listenerCount: number;
  users: UserPresence[];
}

type PosterPresenceChannelParams = BaseChannelParams & {
  presentationId: string;
  presenterIds: string[];
  onSyncCallback: (entry: PosterPresenceDetails) => void;
};

export class PosterPresenceChannel extends BaseChannel {
  private presentationId: string;
  private presenterIds: string[];
  private onSyncCallback: (entry: PosterPresenceDetails) => void;
  private videoParticipantsPresenceChannel: UserPresenceChannel | null = null;
  private listenersPresenceChannel: UserPresenceChannel | null = null;
  private viewersPresenceChannel: UserPresenceChannel | null = null;

  nbUsersDisplayed = 7;
  videoParticipantsDetails: PresenceDetails | null = null;
  listenersDetails: PresenceDetails | null = null;
  viewersDetails: PresenceDetails | null = null;

  constructor(params: PosterPresenceChannelParams) {
    super(params.connection);

    this.presentationId = params.presentationId;
    this.presenterIds = params.presenterIds;
    this.onSyncCallback = params.onSyncCallback;

    this.videoParticipantsPresenceChannel = new UserPresenceChannel({
      channel: `user.presence/${this.presentationId}.video`,
      connection: params.connection,
      onUpdateCallback: (details: PresenceDetails) => {
        this.videoParticipantsDetails = details;
        this.emitUpdate();
      },
    });

    this.listenersPresenceChannel = new UserPresenceChannel({
      channel: `user.presence/${this.presentationId}.listen`,
      connection: params.connection,
      onUpdateCallback: (details: PresenceDetails) => {
        this.listenersDetails = details;
        this.emitUpdate();
      },
    });

    this.viewersPresenceChannel = new UserPresenceChannel({
      channel: `user.presence/${this.presentationId}`,
      connection: params.connection,
      onUpdateCallback: (details: PresenceDetails) => {
        this.viewersDetails = details;
        this.emitUpdate();
      },
    });
  }

  subscribe() {
    if (this.isSubscribed) return;
    this.videoParticipantsPresenceChannel?.subscribe();
    this.listenersPresenceChannel?.subscribe();
    this.viewersPresenceChannel?.subscribe();
    this.isSubscribed = true;
  }

  unsubscribe() {
    if (!this.isSubscribed) return;
    this.videoParticipantsPresenceChannel?.unsubscribe();
    this.listenersPresenceChannel?.unsubscribe();
    this.viewersPresenceChannel?.unsubscribe();
    this.isSubscribed = false;
  }

  emitUpdate() {
    const payload: PosterPresenceDetails = {
      videoParticipantsCount: 0,
      listenerCount: 0,
      viewerCount: 0,
      users: [],
    };

    if (this.videoParticipantsDetails) {
      payload.videoParticipantsCount = this.videoParticipantsDetails.totalCount;
      payload.users = payload.users.concat(this.videoParticipantsDetails.userSubset);

      // move the presenter at the beginning
      payload.users = [
        ...payload.users.filter(({ id }) => this.presenterIds.includes(id)),
        ...payload.users.filter(({ id }) => !this.presenterIds.includes(id)),
      ];
    }

    if (this.listenersDetails) {
      payload.listenerCount = this.listenersDetails.totalCount;
      const subset = this.listenersDetails.userSubset.filter(({ id }) => !payload.users.some(u => u.id === id));
      const remaining = this.nbUsersDisplayed - payload.users.length;
      payload.users = payload.users.concat(subset.slice(0, remaining));
    }

    if (this.viewersDetails) {
      payload.viewerCount = this.viewersDetails.totalCount;
      const subset = this.viewersDetails.userSubset.filter(({ id }) => !payload.users.some(u => u.id === id));
      const remaining = this.nbUsersDisplayed - payload.users.length;
      payload.users = payload.users.concat(subset.slice(0, remaining));
    }

    this.onSyncCallback(payload);
  }
}
