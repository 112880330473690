
import { Component, Prop } from 'nuxt-property-decorator';
import { orderBy, throttle } from 'lodash-es';
import InfiniteLoading from 'vue-infinite-loading';
import { MessageDto } from '../../services';
import NoResults from '../blocks/NoResults.vue';
import MessagesNoResults from '../../images/messages_no_results.png';
import BaseMessagingComponent from './BaseMessagingComponent';
import LiveChatMessage from './LiveChatMessage.vue';
import LiveMessageInput from './LiveMessageInput.vue';

@Component({
  components: {
    InfiniteLoading,
    LiveMessageInput,
    LiveChatMessage,
    NoResults,
  },
})
export default class LiveChat extends BaseMessagingComponent {
  @Prop(Boolean) isHeadless!: boolean;
  @Prop(Boolean) isDarkMode!: boolean;

  readonly MessagesNoResults = MessagesNoResults;

  throttledWatchScroll = throttle(this.watchScroll, 350);

  get sortedMessages(): MessageDto[] {
    return orderBy(this.messages, [x => x.creationDate], ['asc']);
  }

  public isGroupedWithPreviousSibling(index: number): boolean {
    // make sure there's at least 1 message
    if (this.sortedMessages.length === 0) return false;

    // the 1st message cannot have a previous sibling
    if (index <= 0) return false;

    // compare user ids
    if (this.sortedMessages[index].userId !== this.sortedMessages[index - 1].userId) {
      return false;
    }

    // compute date diff
    const messageDate = this.$moment(this.sortedMessages[index].creationDate);
    const previousDate = this.$moment(this.sortedMessages[index - 1].creationDate);
    return messageDate.subtract(10, 'minute').isBefore(previousDate);
  }

  public watchScroll() {
    this.$nextTick(() => {
      const container = this.$refs.chatContainer as HTMLElement;
      if (container) {
        const scrollTopMax = container.scrollHeight - container.clientHeight;
        const distanceY = 200;
        this.canAutoScroll = scrollTopMax - distanceY < container.scrollTop;
      }
    });
  }

  public scrollDownOnNewMessage() {
    if (!this.canAutoScroll) return;
    const container = this.$refs.chatContainer as HTMLElement;
    const scrollTopMax = container.scrollHeight - container.clientHeight;

    if (scrollTopMax - 30 < container.scrollTop) {
      this.$nextTick(() => {
        (this.$refs.scrollUtil as HTMLElement)?.scrollIntoView();
      });
    }
  }

  public scrollDown() {
    this.autoScrollDown();
  }
}
