import { render, staticRenderFns } from "./SessionsModule.vue?vue&type=template&id=24c7f8e2"
import script from "./SessionsModule.vue?vue&type=script&lang=ts"
export * from "./SessionsModule.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports