
import { Component, Prop, Ref, Vue } from 'nuxt-property-decorator';
import linkifyElement from 'linkify-element';
import { MessageDto, MessageReactionType, isValidationError } from 'fourwaves-shared';
import { UserProfilePicture, ReactionPicker, ActionButton } from 'fourwaves-shared/components';

type Reaction = {
  className: string;
  count: number;
  isOwner: boolean;
};

type ReactionKeyedMap<T> = Partial<Record<MessageReactionType, T>>;

const reactionClassMap: ReactionKeyedMap<string> = {
  [MessageReactionType.ThumbsUp]: 'twa-thumbs-up',
  [MessageReactionType.ThumbsDown]: 'twa-thumbs-down',
  [MessageReactionType.ClappingHands]: 'twa-clapping-hands',
  [MessageReactionType.GrinningFaceWithBigEyes]: 'twa-grinning-face-with-smiling-eyes',
  [MessageReactionType.FaceWithTearsOfJoy]: 'twa-face-with-tears-of-joy',
  [MessageReactionType.FaceWithOpenMouth]: 'twa-face-with-open-mouth',
  [MessageReactionType.RedQuestionMark]: 'twa-red-question-mark',
};

@Component({
  components: {
    UserProfilePicture,
    ReactionPicker,
  },
})
export default class DirectMessagingChatEntry extends Vue {
  @Prop({ required: true }) readonly message!: MessageDto;
  @Prop({ type: Number, default: 0 }) readonly appearDelay!: number;
  @Prop(Boolean) readonly isCurrentUser!: boolean;
  @Prop(Boolean) readonly hasImage!: boolean;

  @Ref() textNode?: HTMLParagraphElement;
  @Ref() reactionPickerNode?: ActionButton;

  isReactionPickerVisible = false;
  pickerPositionX = 0;

  get rootClassName() {
    return {
      '-outgoing': this.isCurrentUser,
      '-with-image': this.hasImage,
      '-with-reactions': this.hasReactions,
    };
  }

  get reactions(): ReactionKeyedMap<Reaction> {
    return this.message.reactions.reduce((reactionMap, { userId, type }) => {
      const reaction = reactionMap[type] || { className: reactionClassMap[type], isOwner: false, count: 0 };
      reaction.isOwner = reaction.isOwner || userId === this.$auth.user.id;
      reaction.count += 1;
      return { ...reactionMap, [type]: reaction };
    }, {});
  }

  get userProps() {
    const { userId, firstName, lastName } = this.message;
    return { userId, firstName, lastName };
  }

  get hasReactions() {
    return this.message.reactions.length > 0;
  }

  get reactionPickerStyle() {
    return this.pickerPositionX ? { '--picker-offset': `${this.pickerPositionX}px` } : {};
  }

  get messageEffectDirection() {
    return this.isCurrentUser ? 'left' : 'right';
  }

  async mounted() {
    await this.$nextTick();
    if (!this.textNode) return;

    linkifyElement(this.textNode, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });
  }

  beforeDestroy() {
    this.$bus.off('dm:close-reaction-pickers', this.closeReactionModal);
  }

  public toggleReactionPickerVisibility() {
    if (this.isReactionPickerVisible) this.closeReactionModal();
    else this.openReactionModal();
  }

  public openReactionModal() {
    this.$bus.emit('dm:close-reaction-pickers');
    const offsetParentElement = this.$scrollContext('chat')?.elements.scroll;
    const parentPosition = offsetParentElement?.getBoundingClientRect()[this.messageEffectDirection] || 0;
    const pickerPosition = this.reactionPickerNode?.$el.getBoundingClientRect()[this.messageEffectDirection] || 0;
    const pickerOffset = this.isCurrentUser
      ? pickerPosition - 107 - parentPosition
      : parentPosition - (pickerPosition + 107);

    if (pickerOffset < 0) this.pickerPositionX = this.isCurrentUser ? Math.abs(pickerOffset) : pickerOffset;
    this.$bus.on('dm:close-reaction-pickers', this.closeReactionModal);
    this.isReactionPickerVisible = true;
  }

  public closeReactionModal() {
    this.$bus.off('dm:close-reaction-pickers', this.closeReactionModal);
    this.isReactionPickerVisible = false;
  }

  public async toggleReaction(type: string) {
    this.closeReactionModal();
    const hasReaction = this.reactions[type] && this.reactions[type]?.isOwner;

    const response = !hasReaction
      ? await this.$api.createMessageReaction(this.message.id, type as MessageReactionType)
      : await this.$api.deleteMessageReaction(this.message.id, type as MessageReactionType);

    if (isValidationError(response)) this.feedErrorBag(response);
  }
}
