
import { Component, Vue, Prop, Watch, Ref, namespace } from 'nuxt-property-decorator';
import { groupBy } from 'lodash-es';
import linkifyElement from 'linkify-element';
import UserProfilePicture from '../users/UserProfilePicture.vue';
import { isValidationError, MessageDto, MessageReactionDto, MessageReactionType } from '../../services';
import { ActionButton } from '../action';
import ReactionPicker from './reaction/ReactionPicker.vue';

const EventsModule = namespace('events');

interface Reaction {
  class: string;
  type: MessageReactionType;
  data?: MessageReactionDto[];
}

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    ReactionPicker,
    UserProfilePicture,
  },
})
export default class LiveChatMessage extends Vue {
  @EventsModule.State currentTime!: string;

  @Ref() readonly text!: HTMLElement;
  @Ref() reactionPickerToggle?: ActionButton;

  @Prop({ required: true }) message!: MessageDto;
  @Prop(Boolean) isHeaderHidden!: boolean;
  @Prop({ required: false }) chatContainer!: HTMLElement;
  @Prop(Boolean) isDarkMode!: boolean;

  isOwner = false;
  reactionPickerVisible = false;
  reactionClassTable: Reaction[] = [
    { class: 'twa-thumbs-up', type: MessageReactionType.ThumbsUp },
    { class: 'twa-thumbs-down', type: MessageReactionType.ThumbsDown },
    { class: 'twa-clapping-hands', type: MessageReactionType.ClappingHands },
    { class: 'twa-grinning-face-with-smiling-eyes', type: MessageReactionType.GrinningFaceWithBigEyes },
    { class: 'twa-face-with-tears-of-joy', type: MessageReactionType.FaceWithTearsOfJoy },
    { class: 'twa-face-with-open-mouth', type: MessageReactionType.FaceWithOpenMouth },
    { class: 'twa-red-question-mark', type: MessageReactionType.RedQuestionMark },
  ];

  pickerPositionX = 0;

  @Watch('reactions')
  onReactionsChanged() {
    this.$emit('scrollDown');
  }

  get rootClassNames() {
    return {
      'live-chat__message--owner': this.isOwner,
      'live-chat__message--no-header': this.isHeaderHidden,
      '-owner': this.isOwner,
      '-no-header': this.isHeaderHidden,
      '-dark': this.isDarkMode,
    };
  }

  get shortName(): string {
    return `${this.message.firstName} ${this.message.lastName[0].toUpperCase()}.`;
  }

  get fullName(): string {
    return `${this.message.firstName} ${this.message.lastName}`;
  }

  get reactions(): Reaction[] {
    const dict = groupBy(this.message.reactions, r => r.type);
    return this.reactionClassTable
      .filter(x => x.type in dict)
      .map(x => {
        x.data = dict[x.type];
        return x;
      });
  }

  created() {
    this.isOwner = this.$auth.user.id === this.message.userId;
  }

  mounted() {
    if (this.text) {
      linkifyElement(this.text, { defaultProtocol: 'https', rel: 'noopener noreferrer', target: '_blank' });
    }
  }

  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside);
  }

  public handleClick() {
    if (this.reactionPickerVisible) {
      this.closeReactionModal();
    } else {
      this.openReactionModal();
    }
  }

  public openReactionModal() {
    // Prevent reaction picker from overflowing the viewport;
    if (!this.reactionPickerToggle) return;
    const toggle = this.reactionPickerToggle?.$el as HTMLElement;
    const togglePos = toggle.getBoundingClientRect();
    const scrollBarWidth = this.chatContainer?.offsetWidth - this.chatContainer?.clientWidth;
    const marginRight = scrollBarWidth ? scrollBarWidth + 8 : 0;
    const overflowPoint = window.innerWidth - (togglePos.right + 107) - marginRight;

    if (overflowPoint < 0) this.pickerPositionX = Math.abs(overflowPoint);

    setTimeout(() => document.addEventListener('click', this.clickOutside), 50);
    this.reactionPickerVisible = true;
  }

  public closeReactionModal() {
    this.reactionPickerVisible = false;
    document.removeEventListener('click', this.clickOutside);
  }

  public clickOutside(event) {
    const reactionPicker = this.$refs.reactionPicker as HTMLElement;

    if (event.target !== reactionPicker && !reactionPicker.contains(event.target)) {
      this.closeReactionModal();
    }
  }

  public isCurrentUserReaction(reaction: Reaction): boolean {
    return reaction.data?.find(x => x.userId === this.$auth.user.id) !== undefined;
  }

  public async toggleReaction(type: MessageReactionType) {
    this.closeReactionModal();
    const hasReaction =
      this.reactions.find(x => x.type === type)?.data?.find(x => x.userId === this.$auth.user.id) !== undefined;

    const response = await (!hasReaction
      ? this.$api.createMessageReaction(this.message.id, type)
      : this.$api.deleteMessageReaction(this.message.id, type));
    if (isValidationError(response)) {
      this.feedErrorBag(response);
    }
  }
}
